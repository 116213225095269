main {
  min-height: 80vh;
}
.navbar-new-trade {
  color: red;
}

.navbar-new-trade:hover {
  color: rgb(255, 122, 122);
}

.logo {
  height: 35px;
}

.dashboard-card {
  color: white;
}
.table-header {
  font-size: 1.5rem;
  text-align: center;
}

.table-header-subtext {
  font-size: 0.7rem;
  text-align: center;
}

.journal-table {
  width: 100%;
  white-space: nowrap;
  text-align: center;
}

.account-data-table {
  width: 100%;
  white-space: nowrap;
  border: black 1px solid;
}

.trade-input-card {
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 10px;
  box-shadow: black 0px 0px 4px;
}

.chart-title {
  text-align: center;
}

.chart-card {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 5px;
  box-shadow: black 0px 0px 4px;
}
